import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

function TeachersInClass({ data }) {
  const { viewTeachers, setviewTeachers } = data;
  const { Teachers } = viewTeachers;

  const handleClose = () => {
    setviewTeachers({ Open: false });
  };

  return (
    <Modal open={viewTeachers.Open}>
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          padding: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Teacher's Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Teachers?.map((Teacher, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{Teacher.teachersName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <Button variant="contained" onClick={handleClose} color="warning">
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default TeachersInClass;
