import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

function CourcesInClass({ data }) {
  const { viewCources, setviewCources } = data;
  const { Open, Cources } = viewCources;
  const handleClose = () => {
    setviewCources({ ...viewCources, Open: false });
  };
  return (
    <Modal open={Open}>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: "300px",
          padding: "30px",
          position: "absolute",
          left: "50%",
          top: "50%",
          width: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Course Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Cources.map((Cource, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>{Cource.courceName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ textAlign: "center" }}>
          <Button
            onClick={handleClose}
            sx={{ margin: "20px auto", width: "80px" }}
            color="warning"
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CourcesInClass;
